import React from "react";
import Footer from "src/components/Footer_en";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/en/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/en/our-products/" target="_self">
                      Our Products
                    </a>
                    <ul>
                      <li>
                        <a href="/en/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/ultra_light/">Ultra Light</a>
                      </li>
                      <li>
                        <a href="/en/our-products/protect_cool/">
                          Protect &amp; Cool
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/instantglow/">Instant Glow</a>
                      </li>
                      <li>
                        <a href="/en/our-products/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/en/our-products/wetskin/">Wet skin</a>
                      </li>
                      <li>
                        <a href="/en/our-products/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/en/our-products/aftersun/">After sun</a>
                      </li>
                      <li>
                        <a href="/en/our-products/all_products/">
                          All products
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/en/our-heritage/" target="_self">
                      Our Heritage
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/en/know_the_sun/" target="_self">
                      Know the sun
                    </a>
                  </li>
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      countries
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/aftersun/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/aftersun/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/after-sun/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/aftersun/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/aftersun/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/aftersun/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/2017-pizbuin_1680x405px_After_Sun_UK.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/after_sun_960x405.jpg"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN<sup>®</sup> AFTER SUN
                    </h1>
                    <div className="products-page__intro__description">
                      <h3>Skin revitalisation after the sun</h3>
                      <p>
                        After a day in the sun, your skin needs revitalisation
                        and hydration. Treat your skin with our after sun
                        products to restore its natural moisture balance.
                        Enriched with an intense moisturisation complex, PIZ
                        BUIN<sup>®</sup> AFTER SUN helps to protect the skin
                        from dehydration and to keep a longer lasting and more
                        beautiful tan. All PIZ BUIN<sup>®</sup> AFTER SUN
                        products contain Vitamin E, which helps to protect
                        against premature skin ageing linked to sun exposure.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="soothing-cooling-moisturising-lotion" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/5f883c359b9eeeed84183c6dab5c1c41_f20.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>SOOTHING &amp; COOLING MOISTURISING LOTION</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFITS</h4>
                        <p>
                          Enriched with Aloe Vera, new PIZ BUIN<sup>®</sup>{" "}
                          AFTER SUN Soothing &amp; Cooling Moisturising Lotion
                          provides 24h moisturisation to restore the natural
                          moisture balance of sun stressed skin and help prevent
                          peeling for a longer lasting tan. The ultra fresh,
                          non-greasy and fast absorbing formula contains mint
                          extract for an immediate and lasting cooling effect.
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            PIZ BUIN<sup>®</sup> AFTER SUN Soothing &amp;
                            Cooling Moisturising Lotion in favour with
                            consumers. We asked consumers to tell us what they
                            thought of PIZ BUIN<sup>®</sup> AFTER SUN Soothing
                            &amp; Cooling Moisturising Lotion*.
                          </p>
                          <p>
                            <strong>
                              <span className="orange">100%</span>
                            </strong>{" "}
                            experienced:
                          </p>
                          <ul>
                            <li>Cooled, soothed and moisturised skin</li>
                            <li>Visibly softer skin </li>
                          </ul>
                          <p>
                            <strong>
                              <span className="orange">More than 90%</span>
                            </strong>{" "}
                            experienced:
                          </p>
                          <ul>
                            <li>An immediate cooling effect </li>
                            <li>
                              Immediately soothed and visibly smoother skin
                            </li>
                            <li>Immediately comforted skin</li>
                            <li>A restored natural moisture balance</li>
                          </ul>
                          <p>
                            <strong>
                              <span className="orange">More than 80%</span>
                            </strong>{" "}
                            experienced:
                          </p>
                          <ul>
                            <li>A fast absorbing and non greasy formula</li>
                          </ul>
                          <h2>Available in sizes</h2>
                          <p>200 ml and 400 ml</p>
                          <p>
                            <span className="footNote">
                              *Self-assessment, 31 subjects, 3 weeks
                            </span>
                          </p>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Read more
                        </button>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTS AND TECHNOLOGIES{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                ALOE VERA
                                <div className="textBlock bottom">
                                  <p>
                                    Why is Aloe Vera so good?
                                    <br />
                                    Aloe Vera is a rich natural moisturiser with
                                    powerful soothing properties.{" "}
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/006e44dceb4947a77acdd3948503af8d_f37.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                GLYCERINE
                                <div className="textBlock bottom">
                                  <p>
                                    Glycerine attracts water and holds it in the
                                    skin to help maintain a good hydration
                                    level.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/617fb948ebbaeb31693807e5a660cfbb_f43.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                VITAMIN E
                                <div className="textBlock bottom">
                                  <p>
                                    Vitamin E is a powerful natural antioxidant
                                    that helps to protect from free radicals,
                                    which damage skin cells* and cause premature
                                    skin ageing.
                                    <br />
                                    <span className="footNote">
                                      <br />* In vitro
                                      <br />
                                      <br />
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                MINT EXTRACTMINT EXTRACT
                                <div className="textBlock bottom">
                                  <p>
                                    Mint is widely used for various medicinal
                                    and cosmetic purposes. Amongst other it’s
                                    known for it’s strong natural cooling
                                    properties.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/fdf3033b2f5f4b9f6c2f8a54acf02d47_f45.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="tan-intensifying-moisturising-lotion" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/7fe593e9f045d355b8b43661d26dbfa3_f19.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>TAN INTENSIFYING MOISTURISING LOTION</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFITS</h4>
                        <p>
                          PIZ BUIN<sup>®</sup> AFTER SUN Tan Intensifying Lotion
                          contains Tanimel, a tan intensifying plant extract, to
                          help increase the production of the skin’s natural
                          tanning pigments*. The fast absorbing formula provides
                          24-hour moisturisation to restore the natural moisture
                          balance of sun-stressed skin and help prevent peeling
                          for a longer lasting tan.
                          <br />
                          <span className="footNote">*In vitro test</span>
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTS AND TECHNOLOGIES{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                GLYCERINE
                                <div className="textBlock bottom">
                                  <p>
                                    Glycerine attracts water and holds it in the
                                    skin to help maintain a good hydration
                                    level.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/617fb948ebbaeb31693807e5a660cfbb_f43.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                VITAMIN E
                                <div className="textBlock bottom">
                                  <p>
                                    Vitamin E is a powerful natural antioxidant
                                    that helps to protect from free radicals,
                                    which damage skin cells* and cause premature
                                    skin ageing.
                                    <br />
                                    <span className="footNote">
                                      <br />* In vitro
                                      <br />
                                      <br />
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                SHEA BUTTER
                                <div className="textBlock bottom">
                                  <p>
                                    Shea butter is derived from the shea nut
                                    tree, which originally grows in Western
                                    Africa. It has been used for centuries to
                                    moisturise and protect skin from the effects
                                    of sun, wind, heat and salt water. It is
                                    also widely used in cosmetics because of its
                                    nourishing, moisturising and protective
                                    properties.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/4e8fd1f288f22f5c0578667ed26ebe09_f46.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                TANIMEL
                                <div className="textBlock bottom">
                                  <p>
                                    Tanimel is an active ingredient derived from
                                    the restharrow plant, which is common in
                                    Europe. It helps to maintain the body's
                                    natural melanin production both in and out
                                    of the sun, so your natural tan lasts
                                    longer. It also increases the production of
                                    melanin by up to 133%*, resulting in a
                                    deeper tan.{" "}
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      *In vitro test
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/6121365f8827c636e3a96d865c44ae54_f47.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="instant-mist-spray" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-after-sun-instant-mist-spray-200ml.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>PIZ BUIN® After Sun Instant Relief Mist Spray </h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFITS</h4>
                        <p>
                          With Hyaluronic Acid and Glycerin.
                          <br />
                          Provides 48h moisturisation:
                          <br />- To help restore the natural moisture balance
                          of sun dehydrated skin your skin.
                          <br />- To help prevent peeling for a longer lasting
                          tan. Ultra-light texture.
                          <br />
                          Fast absorption. Non-greasy &amp; non sticky.
                          <br />
                          Soothing freshness to comfort your skin.
                        </p>
                        <div className="products-page__product__details__directions">
                          <h2>DIRECTIONS</h2>
                          <p>
                            For 48H moisturisation reapply after shower or bath
                            on towel dried skin. Precautions: Do not spray near
                            eyes or face. Use caution, as floor may become
                            slippery during use. Avoid inhalation. Use only in
                            well ventilated area.
                          </p>
                        </div>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTS AND TECHNOLOGIES{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                <div className="textBlock bottom">
                                  <p>With Hyaluronic Acid and Glycerin. </p>
                                  <div> </div>
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/en/our-products/moisturising/"
                      target="_self"
                    >
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/ultra_light/" target="_self">
                      Ultra Light
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/protect_cool/" target="_self">
                      Protect &amp; Cool
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/tan_protect/" target="_self">
                      Tan &amp; Protect
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/instantglow/" target="_self">
                      Instant Glow
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/allergy/" target="_self">
                      Allergy
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/wetskin/" target="_self">
                      Wet skin
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/mountain/" target="_self">
                      Mountain
                    </a>
                  </li>
                  <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/en/our-products/aftersun/"
                      target="_self"
                    >
                      After sun
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/all_products/" target="_self">
                      All products
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
        <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
